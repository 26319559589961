
































































































































































































import Vue from 'vue'
import axios from 'axios'

export class OrderForm {
  public company: string | null;
  public name: string | null;
  public email: string | null;
  public tel: string | null;
  public fax: string | null;
  public rEmail: number | null;
  public rTel: number | null;
  public rFax: number | null;
  public free: string | null;
  // public gRecaptchaResponse: string | null;
  constructor () {
    this.company = null
    this.name = null
    this.email = null
    this.tel = null
    this.fax = null
    this.rEmail = 0
    this.rTel = 0
    this.rFax = 0
    this.free = null
    // this.gRecaptchaResponse = null
  }
}

export default Vue.extend({
  name: 'OptSearch',
  data: function () {
    return {
      loading: false,
      dialog: false,
      message: '',
      dialog_error: false,
      errorMessage: '',
      orderForm: new OrderForm(),
      recaptchaKey: '6LdblqQfAAAAABulaiBYz9Vt3C9BN4jYFrWh9Xfe',
      emailRules: [
        (value: string) => !!value || 'メールは必須項目です。',
        (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || '正しいメールアドレスの書式で入力してください。'
      ],
      companyRules: [
        (value: string) => !!value || '会社名は必須項目です。'
      ],
      nameRules: [
        (value: string) => !!value || '担当者名は必須項目です。'
      ],
      isValid: true
    }
  },
  computed: {
    form (): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }
    }
  },
  methods: {
    async postForm () {
      try {
        if (!this.form.validate()) {
          this.errorMessage = '必須項目が入力されていません。'
          this.dialog_error = true
        } else {
          // this.orderForm.gRecaptchaResponse = document.getElementById('recaptcha-token')!.nodeValue ? document.getElementById('recaptcha-token')!.nodeValue : null
          const response = await axios.post('/v1/toiawase.php', this.orderForm)
          if (response.data.error) {
            this.message = response.data.message
            this.dialog_error = true
            return
          }

          if (response.data.message) {
            this.message = response.data.message
            this.dialog = true
            return
          }
        }
      } catch (e) {
        console.log('フォームの送信に失敗：', this.orderForm)
        console.error(e)
      }
    },
    sendReset () {
      this.dialog = false
    }
  }
})
